import "./App.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const characters = [
  {
    image: "bandit",
    title: "Outlaw",
        desc: "Tauche als Outlaw in die düstere Unterwelt des Verbrechens ein. Plane und führe sorgfältig geplante Raubzüge aus, knüpfe Kontakte zu anderen Kriminellen und werde ein wahrer Meister des Wilden Westens. Kannst du es mit deinen Rivalen aufnehmen und dir einen Namen als der gefährlichste Gesetzlose im Wilden Westen machen?",  },
  {
    image: "fancy",
    title: "Bürger",
      desc: "Nehme die Rolle eines normalen Bürgers an. Du kannst deinen Träumen nachgehen, arbeiten, dich entwickeln und Beziehungen zu anderen Bewohnern aufbauen. Entscheide dich für ein ruhiges Leben in der Stadt oder lasse dich zu Abenteuern verleiten und begebe dich auf unbekannte Pfade? Die Entscheidung liegt bei dir.",    mask: "template-1",
  },
  {
    image: "sheriff",
    title: "Sheriff",
      desc: "Schlüpfen in die Rolle eines entschlossenen Ordnungshüters und wähle den Weg eines Sheriffs. Auf diesem Weg bist du für die Durchsetzung der Ordnung und die Aufrechterhaltung des Gesetzes verantwortlich. Streife durch die Straßen, verfolge Verbrecher, führe Ermittlungen durch und verteidige die Sicherheit der Bürger. Kannst du die schwierigen Herausforderungen meistern und dem ständigen Chaos trotzen?",    mask: "template-2",
  },
  {
    image: "native",
    title: "Native",
      desc: "Schlüpfe in die Rolle eines indianischen Kriegers, der sein Land bewacht und sein Volk gegen Eindringlinge verteidigt. Entdecke die Geheimnisse uralter Rituale, gehe auf die Jagd und baue eine Verbindung zur Natur auf. Wirst du dich den Herausforderungen stellen und zur Legende deines Stammes werden?",    mask: "template-3",
  },
  {
    image: "doctor",
    title: "Doktor",
      desc: "Schlüpfe in die Rolle eines engagierten Arztes, der sich der Rettung von Menschenleben verschrieben hat. Übernehme die Kontrolle über das Leben der Patienten, treffe schwierige medizinische Entscheidungen, diagnostiziere Krankheiten und leiste Hilfe in Notfällen. Wirst du dich den immensen Herausforderungen stellen und ein Leuchtfeuer der Hoffnung für die Kranken werden?",    mask: "template-4",
  },
];

const settings = {
  className: "center",
  // centerMode: true,
  infinite: true,
  slidesToShow: 3,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 500,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Character = ({ image, title, desc, mask }) => {
  return (
    <>
      <div className="image-element">
        <div className={`image-mask ${mask}`}>
          <div className={`image ${image}_p`}>
            <div className={`image2 ${image}`}></div>
          </div>
        </div>
        <span>
          <p>{title}</p>
          <div className="line"></div>
          <br></br>
          {desc}
        </span>
      </div>
    </>
  );
};

function Characters() {
  return (
    <div className="grid-x1v2">
      <Slider {...settings}>
        {characters.map((column, index) => (
          <Character
            key={index}
            image={column.image}
            title={column.title}
            desc={column.desc}
            mask={column.mask}
          ></Character>
        ))}
      </Slider>
    </div>
  );
}

export default Characters;
