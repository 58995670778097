import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useMeasure from "react-use-measure";
import { useSpring, animated } from "react-spring";

import "./Navigation.scss";

import logo from "./logo.png";

const HamburgerMenu = styled(animated.div)`
  overflow: hidden;
`;

const Navigation = () => {
    // Przykładowe dane dla statusu serwera
    const serverStatus = {
        online: true,
        message: "ONLINE",
    };

    const [isCollapsed, setIsCollapsed] = useState(true);
    const [ref, bounds] = useMeasure();
    const panelCOntentAnimatedStyle = useSpring({
        height: isCollapsed ? 0 : bounds.height,
    });

    const handleClick = () => {
        const menu = document.querySelector(".hamburger-menu");

        if (!isCollapsed) {
            setTimeout(() => {
                menu.classList.toggle("active");
            }, 500);
        } else {
            menu.classList.toggle("active");
        }

        setIsCollapsed((prevState) => !prevState);
    };

    const checkResize = () => {
        const hamburger = document.querySelector(".hamburger");
        const menu = document.querySelector(".options");
        const status = document.querySelector(".server-status");
        if (!menu || !hamburger || !status) {
            return;
        }
        if (window.innerWidth > 768) {
            menu.classList.add("active");
            status.classList.add("active");
            hamburger.classList.remove("active");
            setIsCollapsed(true);
        } else {
            menu.classList.remove("active");
            status.classList.remove("active");
            hamburger.classList.add("active");
        }
    };

    useEffect(() => {
        checkResize();
        window.addEventListener("resize", checkResize);
    }, []);

    return (
        <>
            <nav>
                <div className="logo">
                    <a href="/#">
                        <img src={logo} alt="Logo strony" width="128" height="128" />
                    </a>
                </div>
                <div className="options">
                    <a href="/#">Home</a>
                    <a href="#/rules">
                        Regeln
                    </a>
                    <a href="/perks">Skill-System</a>
                    <a href="https://discord.gg/TXAzjKH8W3" target="_blank" rel="noopener noreferrer">
                        Discord
                    </a>
                </div>
                <div className="server-status">
                    SERVER STATUS:{" "}
                    <span className={serverStatus.online ? "online" : ""}>
                        {serverStatus.message}
                    </span>
                </div>
                <div className="hamburger" onClick={() => handleClick()}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
            </nav>
            <HamburgerMenu
                className="hamburger-menu"
                style={panelCOntentAnimatedStyle}
            >
                <div className="hamburger-menu-items" ref={ref}>
                    <a href="/#">Home</a>
                    <a href="https://sites.google.com/view/wlrpderw" target="_blank" rel="noopener noreferrer">Regeln</a>
                    <a href="/perks">Skill-System</a>
                    <a href="https://discord.gg/TXAzjKH8W3" target="_blank" rel="noopener noreferrer">Discord</a>
                    <a>
                        SERVER STATUS:{" "}
                        <span className={serverStatus.online ? "online" : ""}>
                            {serverStatus.message}
                        </span>
                    </a>
                </div>
            </HamburgerMenu>
        </>
    );
};

export default Navigation;
