import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Rules from "./Rules";
// import reportWebVitals from './reportWebVitals';
import { HashRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HashRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/policy" element={<App />} />
        <Route path="/tos" element={<App />} />
        <Route path="/perks" element={<App />} />
      </Routes>
    </HashRouter>
  </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
