import React, { useEffect, useState } from "react";
import "./App.scss";

import Navigation from "./Navigation";

import Discord from "./Discord";

import Footer from "./Footer";

import Characters from "./Characters";

function App() {
    return (
        <>
            <section className="landingpage">
                <Navigation></Navigation>
                <div className="server-title">
                    <span>WILDLANDSRP</span>
                    <p>Werde zu Legende!</p>
                </div>
            </section>
            <section className="firstsection">
                <Characters></Characters>
                <div className="grid-x2">
                    <div className="grid-element">
                        <div className="image-element">
                            <div className="image">
                                <Discord></Discord>
                            </div>
                        </div>
                    </div>
                    <div className="grid-element">
                        <span>
                            <p>Discord</p>
                            <div className="line"></div>
                            <br></br>
                            Tritt unserem Discord bei und werde Teil der wachsenden WildLands Roleplay
                            Gemeinschaft! Bleib auf dem Laufenden mit den neuesten Nachrichten, Events und
                            Updates über den Server. Triff andere Spieler, tausche Eindrücke aus,
                            stelle Fragen und nehme an Diskussionen über das Spiel teil. Ob
                            du ein Outlaw, ein Sheriff oder ein normaler Bürger bist, unser Discord ist
                            der Ort, an dem du Leute mit ähnlichen Interessen treffen und
                            in die Welt von Red Dead Redemption Roleplay eintauchen kannst. Wir
                            freuen uns darauf, dass du Teil unserer Geschichte wirst!
                            <p>
                                <a href="https://discord.gg/TXAzjKH8W3" target="_blank" rel="noopener noreferrer">
                                    Join now!
                                </a>
                            </p>
                        </span>
                    </div>
                </div>

                <div className="section-title">Unser Team</div>
                <div className="section-subtitle">
                    Unser erfahrenes Team verbindet Kompetenz in Projektleitung
                    und Administration mit dem Know-how führender Creator in der MLO- und Skripterstellung.
                    Mit langjähriger Erfahrung und einer gemeinsamen Leidenschaft für Roleplay schaffen wir eine Welt,
                    in dem RP-Fans die authentische Atmosphäre des Wilden Westens erleben können.
                    So ermöglichen wir Spielern, gemeinsam eine unvergessliches Geschichte zu schreiben.
                </div>
                <div className="grid-x4">
                    <div className="grid-element">
                        <div className="image-element">
                            <div className="image-mask">
                                <div className="image agaes"></div>
                            </div>
                        </div>
                        <span>
                            <p>Miles</p>
                            Projektleitung, MLO Creator
                        </span>
                    </div>
                    <div className="grid-element">
                        <div className="image-element">
                            <div className="image-mask template-1">
                                <div className="image pixelek3"></div>
                            </div>
                        </div>
                        <span>
                            <p>Hunter</p>
                            Administrator, Projektentwickler
                        </span>
                    </div>
                    <div className="grid-element">
                        <div className="image-element">
                            <div className="image-mask template-2">
                                <div className="image bysiu"></div>
                            </div>
                        </div>
                        <span>
                            <p>Ktoś</p>
                            Hauptentwickler, MLO Creator
                        </span>
                    </div>
                    <div className="grid-element">
                        <div className="image-element">
                            <div className="image-mask template-3">
                                <div className="image tafe"></div>
                            </div>
                        </div>
                        <span>
                            <p>Tafé</p>
                            MLO Creator
                        </span>
                    </div>
                </div>
                <div className="grid-x1">
                    <div className="grid-element">
                        <div className="section-title">JOIN NOW!</div>
                        <div className="section-subtitle">
                            Hast du jemals davon geträumt, in die Rolle eines Kopfgeldjägers, eines
                            rücksichtslosen Revolverhelden, eines Sheriffs der das Gesetz bewacht, oder vielleicht eines
                            Entdecker des Wilden Westens zu sein? Auf WildLandsRP werden all diese
                            Möglichkeiten Wirklichkeit werden. Wir bieten DIR ein einzigartiges und
                            unvergessliches Erlebnis, das dich direkt in das Herz des Wilden Westens führt.
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    );
}

export default App;
