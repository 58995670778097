import React from "react";

function Discord() {
  return (
    <iframe
      title="Discord Widget"
      src="https://discord.com/widget?id=1303405599416914005&theme=dark"
      width="100%"
      height="100%"
      allowtransparency="true"
      frameBorder="0"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
    />
  );
}

export default Discord;
