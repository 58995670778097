import React from "react";

import "./ArticleNavigation.scss";

function ArticleNavigation({ data }) {
  const handleNavigationClick = (e) => {
    e.preventDefault();
    const targetId = e.target.getAttribute("href").substring(1);
    const targetElement = document.getElementById(targetId);
    if (!targetElement) {
      return;
    }
    const elementPosition = targetElement.getBoundingClientRect().top;
    const offsetPosition =
      elementPosition + window.pageYOffset - (10 * window.innerHeight) / 100;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  return (
    <div class="article-navigation-wrapper">
      <div class="article-navigation">
        {data.map((item) => (
          <div
            key={item.id}
            className={`article-navigation-link depth-${item.depth}`}
          >
            <a href={`#${item.id}`} onClick={handleNavigationClick}>
              {item.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ArticleNavigation;
