import React from "react";
import "./App.scss";

function Footer() {
    return (
        <>
            <footer>
                <div className="rights">
                    <h3>Rights</h3>
                    <div className="line"></div>
                    Dieser Server ist nicht mit Rockstar North, Take-Two Interactive oder
                    anderen Rechteinhabern verbunden oder wird von ihnen unterstützt.
                    Alle verwendeten Warenzeichen gehören ihren jeweiligen Eigentümern.
                </div>
                <div className="socials">
                    <h3>Socials</h3>
                    <div className="line"></div>
                    <p>
                        <a href="https://www.youtube.com/@WildLandsRPde" target="_blank" rel="noopener noreferrer">
                            Youtube
                        </a>
                    </p>
                    <p>
                        <a href="https://www.tiktok.com/@wildlandsrp.de" target="_blank" rel="noopener noreferrer">
                            Tiktok
                        </a>
                    </p>
                    <p>
                        <a href="https://discord.gg/TXAzjKH8W3" target="_blank" rel="noopener noreferrer">
                            Discord
                        </a>
                    </p>
                </div>
                <div className="navigation">
                    <h3>Navigation</h3>
                    <div className="line"></div>
                    <p>
                        <a href="/#">Home</a>
                    </p>
                    <p>
                        <a href="#/rules">
                            Regeln
                        </a>
                    </p>
                    <p>
                        <a href="/perks">Skill-System</a>
                    </p>
                </div>
            </footer>
            <footer2>
                <div className="politics">
                    <div className="line"></div>
                    WildLandsRP.de © 2024 | Powered by <a href="https://PolyCode.pl">PolyCode</a>
                </div>
            </footer2>
        </>
    );
}

export default Footer;
