import React from "react";

import Navigation from "./Navigation";

import "./Policy.scss";

import Footer from "./Footer";

import ArticleNavigation from "./ArticleNavigation";

const data = [
    { name: "Allgemeine Regeln", id: "main", depth: 1 },
    { name: "Streaming und Aufnahmen", id: "streaming", depth: 1 },
    { name: "Support", id: "sup", depth: 1 },
    { name: "Fail RP", id: "fail", depth: 1 },
    { name: "Power RP", id: "power", depth: 1 },
    { name: "RDM | HDM | VDM", id: "rdm", depth: 1 },
    { name: "BSG | META | Scripted | Stealth RP", id: "meta", depth: 1 },
    { name: "Anträge", id: "applications", depth: 1 },
    { name: "Gewerbe", id: "shops", depth: 1 },
    { name: "Crimes | Natives | Zivis", id: "crime", depth: 1 },
    { name: "Korruption", id: "corruption", depth: 1 },
];

function Rules() {
    return (
        <div className="Rules">
            <Navigation />
            <section className="grid2">
                <ArticleNavigation data={data}></ArticleNavigation>
                <div className="grid-item">
                    <h2 id="main">Allgemeine Regeln</h2>
                    <p>
                        <div class="bold-text">Respektvoller Umgang: </div>
                        Behandle andere Spieler respektvoll und höflich.
                        Rassismus, Sexismus, schwere Beleidigungen,
                        Hetze oder das absichtliche Ausnutzen von Schwächen anderer sind strengstens verboten und
                        führen zu einem sofortigen Ausschluss.
                    </p>
                    <p>
                        <div class="bold-text">Mindestalter: </div>
                        Um Teil des Projekts zu sein,
                        musst du mindestens 18 Jahre alt sein.
                        Sollten wir im nachhinein herausfinden,
                        dass du unter 18 bist, wirst du vom Projekt ausgeschlossen.
                    </p>
                    <p>
                        <div class="bold-text">Historische Authentizität: </div>
                        Unser RP spielt im Jahr 1898.
                        Bitte achte darauf, dass dein Verhalten und
                        deine Sprache zur damaligen Zeit passen und
                        vermeide moderne Begriffe oder Handlungen.
                    </p>
                    <p>
                        <div class="bold-text">Realistische Charakternamen: </div>
                        Dein Charaktername muss realistisch sein und
                        zur Epoche passen. Namen berühmter Persönlichkeiten sind nicht erlaubt.
                        Die Projektleitung behält sich vor, unpassende Namen zu ändern.
                    </p>
                    <p>
                        <div class="bold-text">NPC-Interaktion: </div>
                        NPCs sind Teil der Welt und
                        müssen im RP wie alle anderen Charaktere behandelt werden.
                    </p>
                    <p>
                        <div class="bold-text">Belebte Welt: </div>
                        Die gesamte Spielwelt ist jederzeit realistisch,
                        als belebte Welt auszuspielen, egal wie viele Spieler sich in der Umgebung aufhalten.
                    </p>
                    <p>
                        <div class="bold-text">Kein Bug- und Exploit-Missbrauch:</div>
                        Die Nutzung von Bugs,
                        Exploits oder Drittanbieter-Programmen ist verboten. Melde gefundene Bugs sofort beim Support.
                    </p>
                    <p>
                        <div class="bold-text">Grauzonen: </div>
                        Das absichtliche Ausnutzen von Regel-Grauzonen führt zum Ausschluss.
                        Vermeide jegliche „Regel-Lücken-Taktik“.
                    </p>
                    <p>
                        <div class="bold-text">Werbung für andere Projekte: </div>
                        Werbung für andere Projekte ist auf unseren Plattformen verboten.
                        Sollte jemand dies tun, melde es bitte umgehend mit einem Screenshot.
                    </p>
                    <p>
                        <div class="bold-text">Unangenehme RP-Situationen: </div>
                        Wenn sich jemand in einer RP-Situation unwohl fühlt,
                        sollte die Szene dennoch zu Ende gespielt und anschließend der Support aufgesucht werden.
                    </p>
                    <p>
                        <div class="bold-text">Meldung von Regelverstößen: </div>
                        Alle Regelverstöße müssen gemeldet werden. Die Meldefrist beträgt 48 Stunden.
                    </p>
                    <p>
                        <div class="bold-text">Trolling: </div>
                        Das gezielte Stören oder Belästigen anderer Spieler ist verboten,
                        ebenso wie das wiederholte Verfolgen oder Provozieren ohne RP-Grund.
                    </p>
                    <p>
                        <div class="bold-text">Soundboards: </div>
                        Soundboards sind nicht erlaubt.
                    </p>
                    <p>
                        <div class="bold-text">Aufnahmen: </div>
                        Jeder Spieler ist verpflichtet,
                        auf Aufforderung des Supports sämtliche auf dem Server erstellten Videoaufnahmen (Bsp. Clips, VOD's) zur Verfügung zu stellen.
                    </p>
                    <p>
                        <div class="bold-text">Regelanpassungen: </div>
                        WildLandsRP behält sich das Recht vor, die Regeln jederzeit anzupassen.
                    </p>
                    <p>
                        <div class="bold-text">Gemeinsame Einreise: </div>
                        Spieler können alleine oder in einer Gruppe einreisen, sofern die Hintergrundgeschichte übereinstimmt. Nachzügler mit einer gemeinsamen
                        Vorgeschichte können innerhalb von 3 Tagen nachkommen, danach muss man sich im RP neu “Kennenlernen” und gilt als Fremd. 
                    </p>
                    <p>
                        <div class="bold-text">Ermessens-Regel: </div>
                        Da nicht jede Situation in einer Regel festgehalten werden kann,
                        behält sich WildLandsRP das Recht vor, bei nicht eindeutig definierten Verstößen nach eigenem Ermessen zu entscheiden.
                    </p>
                    <h2 id="streaming">Streaming und Aufnahmen</h2>
                    <p>
                        <div class="bold-text">Aufnahme- und Streaming-Erlaubnis: </div>
                        <div class="highlight">Mit dem durchlaufen des Whitelist-Gesprächs und
                            der Annahme der Whitelist-Rolle (Bürger) auf dem WildLandsRP.de Discord,
                            erklärt sich die Person damit einverstanden, dass ihr gesprochenes Wort auf WildLandsRP für Live-Streams (z. B. auf Twitch) oder
                            für die Content-Erstellung (z. B. auf YouTube) aufgezeichnet und genutzt werden darf und
                            nach § 201 Absatz 1 und Absatz 2 StGB auf ihr Recht verzichtet, sofern die jeweiligen Streamer oder
                            Ersteller eine entsprechende Erlaubnis erhalten haben (Streamer-Rolle) durch die WildLandsRP Administrative.
                            Eine Teilnahme am Projekt ohne diese Zustimmung ist leider nicht möglich.</div>
                    </p>
                    <p>
                        <div class="bold-text">Streaming / Content Erstellung: </div>
                        Streaming und Content Aufnahmen auf WildLandsRP ist erlaubt und gerne gesehen.
                    </p>
                    <p>
                        <div class="bold-text">Vorgehen: </div>
                    </p>
                    <p>
                        Die Streamer/Content-Creator-Rolle muss zuvor im Support per Ticket beantragt werden und muss folgende Informationen beinhalten:
                    </p>
                    <ul>
                        <li>
                            Link zu/zum eigenen Streaming und oder Content Kanal/Kanälen
                        </li>
                        <li>
                            Charaktername
                        </li>
                    </ul>
                    <p>
                        <div class="bold-text">Bedingung: </div>
                    </p>
                    <p>
                        Das Streaming und Content veröffentlichen ist ausschließlich auf den zuvor angegebenen und
                        vom Support verifizierten Kanälen gestattet. Verstöße gegen diese Regel führt sofort zum Verlust der Streaming-Erlaubnis auf dem Projekt und
                        somit auch zum Entzug des Rechts, das gesprochene Wort anderer Personen auf diesem Projekt aufzuzeichnen.
                    </p>
                    <p>
                        <div class="bold-text">Aufnahmen außerhalb des RP: </div>
                        Außerhalb des RPs dürfen Aufnahmen nur mit Erlaubnis aller anwesenden Personen und
                        einer Kennzeichnung erfolgen.
                    </p>
                    <p>
                        <div class="bold-text">Support-Gespräche streamen / aufnehmen: </div>
                        Support-Gespräche dürfen ohne Ausnahme <div class="highlight_2">NICHT</div> gestreamt oder aufgenommen werden.
                    </p>
                    <p>
                        <div class="highlight">Erklärung: Wir definieren das so klar und hart,
                            um euch und das Projekt soweit es uns möglich ist, gegen sogenannte “Troll” Streamer und
                            Content Creator zu schützen! Alle Anderen sind willkommen und erhalten auch dementsprechend die Streamer-Rolle.</div>
                    </p>
                    <h2 id="sup">Support</h2>
                    <p>
                        <div class="bold-text">Verstöße: </div>
                        Für Konfliktlösungen sind Video- oder Screenshot-Beweise erforderlich.
                    </p>
                    <p>
                        <div class="bold-text">Regelverstöße im laufenden RP: </div>
                        Sollte während einer laufenden RP-Situation ein Regelverstoß festgestellt werden, ist die RP-Situation bis zum Ende weiterzuspielen.
                        Nach Beendigung der Situation steht einem der Support zur Verfügung.
                    </p>
                    <p>
                        <div class="bold-text">Erstattungen: </div>
                        Erstattungen werden ausschließlich mit einem Video- oder Screenshot bearbeitet.
                        Dies gilt ebenso für Gegenstände, die durch einen Spielfehler oder einen Game Crash verloren gegangen sind.
                        Auffällige Erstattungen werden durch den Support hinterfragt und bei einem Verdacht auf Betrug abgelehnt.
                        Sollte ein Betrugsversuch festgestellt werden, so wird dieser bestraft.
                    </p>
                    <h2 id="fail">Fail RP</h2>
                    <p>
                        <div class="bold-text">In-Character bleiben: </div>
                    </p>
                    <ul>
                        <li>
                            Das Verlassen der Rolle oder das Verwenden von OOC-Kommunikation während des RP ist verboten.
                            Verletzungen müssen ausgespielt werden; „Superman-Verhalten“ ist unrealistisch.
                        </li>
                        <li>
                            Vermeide OOC-Begriffe wie „Kopfschmerzen“ für technische Probleme oder Anspielungen auf „Muskeln“ für eine Funktion im Spiel.
                        </li>
                        <li>
                            ID-Nummern dürfen nicht erwähnt oder angedeutet werden, etwa als „Glückszahl“.
                        </li>
                        <li>
                            Dauerhaftes Sprinten oder Springen ist zu unterlassen.
                        </li>
                    </ul>
                    <p>
                        <div class="bold-text">Respawn in RP-Situationen: </div>
                        Respawnen bei Bewusstlosigkeit während der aktiven RP Situation ist verboten.
                    </p>
                    <p>
                        <div class="bold-text">Unrealistische Fähigkeiten: </div>
                        Die Fähigkeiten eines Charakters dürfen sich nicht drastisch ändern.
                        Charaktere entwickeln sich organisch und sind keine Alleskönner.
                    </p>
                    <p>
                        <div class="bold-text">Pferde und Kutschen: </div>
                        Das rufen von Pferden und Kutschen ist nur an Ställen, geeigneten Orten oder am Ort wo man sie weg geschickt hat erlaubt. 
                    </p>
                    <p>
                        <div class="bold-text">Waffen sichtbar tragen: </div>
                        Alle Waffen im Inventar sind sichtbar zu tragen, mit wenigen Ausnahmen für versteckbare Waffen.
                    </p>
                    <p>
                        <div class="bold-text">Langwaffen: </div>
                        Das Tragen oder mit sich führen (im Inventar) von mehr als einer Langwaffe ist verboten.
                    </p>
                    <p>
                        <div class="bold-text">Moneyclip: </div>
                        Wird bei einem Raubüberfall oder einer ähnlichen Situation durch Worte oder die /me-Funktion ausgespielt,
                        dass einer Partei Geld entwendet wird, ist die betroffene Person verpflichtet,
                        das geforderte Geld eigenständig an die andere Partei zu übergeben.
                    </p>
                    <h2 id="power">Power RP</h2>

                    <p>
                        <div class="bold-text">Spielraum lassen: </div>
                        RP ohne eine Wahlmöglichkeit zu bieten oder dein RP aufzuzwingen, ist verboten.
                    </p>
                    <p>
                        <div class="bold-text">Verstümmelungen: </div>
                        Verstümmelungen und Erniedrigungen von bewusstlosen Personen sind untersagt, außer beide Parteien stimmen Narben oder Ähnlichem zu.
                    </p>
                    <p>
                        <div class="bold-text">Bewusstlosigkeit: </div>
                        Während der Bewusstlosigkeit nimmt euer Charakter keinerlei Geschehnisse in seiner Umgebung wahr.
                        Informationen zu den Ereignissen können euch später von einer beteiligten Person im RP erklärt werden.
                    </p>
                    <p>
                        <div class="bold-text">Respawn: </div>
                        Sollte euch niemand versorgen oder zu einem Arzt bringen,
                        müsst ihr spätestens nach zwei Stunden nach eintreten der Bewusstlosigkeit eigenständig "aufwachen".
                        Nach dem "Aufwachen" dürft ihr weder Erinnerungen an die vorherige RP-Situation haben noch
                        euch weiterhin an der laufenden RP-Situation beteiligen.
                    </p>
                    <h2 id="rdm">RDM | HDM | VDM</h2>
                    <p>
                        <div class="bold-text">RDM: </div>
                        Das grundlose Töten von Spielern oder NPCs ist untersagt.
                    </p>
                    <p>
                        <div class="bold-text">HDM | VDM: </div>
                        Das absichtliche Überfahren oder Überreiten von Spielern oder NPCs ohne RP-Grund ist ebenfalls verboten.
                    </p>
                    <h2 id="meta">BSG | META | Scripted | Stealth RP</h2>
                    <p>
                        <div class="bold-text">Meta-Gaming: </div>
                        Informationen die man außerhalb des Spiels (OOC) erhalten hat, dürfen nicht im RP verwendet werden.
                    </p>
                    <p>
                        <div class="bold-text">BSG: </div>
                        Dritte sollen keine Handlungen für andere Spieler vorschlagen, weder im Stream noch per Nachricht.
                    </p>
                    <p>
                        <div class="bold-text">Scripted RP: </div>
                        Alle Handlungen müssen natürlich im RP entstehen, abgesehen von Events der Administration.
                    </p>
                    <p>
                        <div class="bold-text">Stealth RP: </div>
                        Gestalte dein Roleplay so, dass dein Handeln niemals anonym bleibt.
                        Bei Aktionen wie einem Raubüberfall solltest du beispielsweise Hinweise (keine Beweise) hinterlassen,
                        die darauf schließen lassen können, wer die Aggressor-Partei gewesen sein könnte.
                    </p>
                    <h2 id="applications">Anträge</h2>
                    <p>
                        <div class="bold-text">Hinrichtung: </div>
                        Hinrichtungen sind nur mit tiefgründiger RP-Grundlage und schriftlichem genehmigten Antrag erlaubt.
                        Jeder Spieler kann solch einen Antrag einreichen.
                    </p>
                    <p>
                        <div class="bold-text">Suizid: </div>
                        Selbstmord ist nur mit starker RP-Begründung und Support-Absprache erlaubt.
                    </p>
                    <p>
                        <div class="bold-text">Neuer Charakter: </div>
                        Das selbstständige Löschen von Charakteren sowie das Erstellen von neuen Charakteren ist IMMER zuvor im Support zu beantragen.
                        Ein neuer Charakter darf nicht unmittelbar in alte RP-Stränge einsteigen,
                        sich für 14 Tage im alten Gebiet aufhalten oder denselben Beruf wählen.
                    </p>
                    <p>
                        <div class="bold-text">Vererben: </div>
                        Das Verschenken oder Vererben von Vermögen oder Gegenständen ist untersagt.
                    </p>
                    <h2 id="shops">Gewerbe</h2>
                    <p>
                        <div class="bold-text">Aktivität: </div>
                        Gewerbe müssen regelmäßig aktive bespielt werden.
                    </p>
                    <p>
                        <div class="bold-text">Anwesenheit: </div>
                        Sollte ein Gewerbebetreiber länger als eine Woche nicht anwesend sein können,
                        so muss er dies zuvor im Support mitteilen und wenn möglich einen Vertreter benennen.
                    </p>
                    <p>
                        <div class="bold-text">Jobhopping: </div>
                        Das ständige Wechseln des Berufs ist zu vermeiden.
                        Die gewählte Tätigkeit sollte zudem mit dem Charakter und dessen Hintergrund stimmig sein.
                    </p>
                    <p>
                        <div class="bold-text">Realistische Zeiten: </div>
                        Beim Herstellen von Gegenständen ist auf eine realistische Zeitspanne bis zur Fertigung zu achten (Aufwand/Menge).
                    </p>
                    <p>
                        <div class="bold-text">Besitz eines Gewerbes: </div>
                        Wer einen festen Beruf ausübt, darf kein eigenes Gewerbe besitzen.
                        Es ist jedoch erlaubt, zusätzlich als Mitarbeiter in einem Gewerbe tätig zu sein.
                    </p>
                    <h2 id="crime">Crimes | Natives | Zivis</h2>
                    <p>
                        <div class="bold-text">Gründung: </div>
                        Jede Gruppierung muss ein schlüssiges Konzept vorlegen,
                        mindestens 4 und maximal 8 Mitglieder umfassen und sich beim Support registrieren (Crimes / Natives).
                    </p>
                    <p>
                        <div class="bold-text">Aggressor: </div>
                        Pro Gruppierung dürfen maximal 6 Mitglieder gleichzeitig als Aggressoren agieren.
                    </p>
                    <p>
                        <div class="bold-text">Ansprechpartner: </div>
                        Jede Gruppierung ist verpflichtet, einen Leiter sowie einen Stellvertreter zu benennen,
                        die als direkte Ansprechpartner für den Support fungieren.
                    </p>
                    <p>
                        <div class="bold-text">Zivile Gruppen: </div>
                        Zivilisten oder nicht offiziell registrierte Gruppen dürfen in aggressiven Handlungen
                        nur in einer maximalen Gruppengröße von 3 Personen agieren.
                    </p>
                    <p>
                        <div class="bold-text">Allianzen: </div>
                        Das gemeinsame Agieren mehrerer Gruppierungen ist untersagt.
                        Diese Regel kann jedoch bei schwerwiegenden Eskalationen im Roleplay
                        nach Rücksprache mit dem Support temporär außer Kraft gesetzt werden.
                    </p>
                    <p>
                        <div class="bold-text">Bandanas und Maskierung: </div>
                        Ein einfaches Tuch im Gesicht bietet keine vollständige Maskierung.
                        Um unerkannt zu bleiben, verändere deine Kleidung und maskiere dein Gesicht vollständig
                        (Bsp. Brille + Bandana oder ein Bandana dass das ganze Gesicht abdeckt)
                    </p>
                    <p>
                        <div class="bold-text">Gestohlene Gegenstände: </div>
                        Gegenstände, die gestohlen wurden, müssen mindestens 5 Spielstunden lang im eigenen Inventar mitgeführt werden,
                        bevor sie an andere Personen weitergegeben oder anderweitig gelagert werden dürfen.
                    </p>
                    <p>
                        <div class="bold-text">Kleidung: </div>
                        Nach einer kriminellen Handlung dürfen Kleidungsstücke für einen Zeitraum von 40 Minuten weder verändert noch ausgezogen werden.
                        Die Abnahme von Vermummung ist jedoch gestattet.
                    </p>
                    <p>
                        <div class="bold-text">Native Schminke: </div>
                        Natives, die einem Stamm angehören, sind verpflichtet, stets die entsprechende Stammes Schminke im Gesicht zu tragen.
                    </p>
                    <h2 id="corruption">Korruption</h2>
                    <p>
                        <div class="bold-text">Definition: </div>
                        In einer Staatsfraktion steht die Korruption für den Missbrauch seiner Funktion,
                        um für sich oder einen Dritten einen immateriellen oder materiellen Vorteil zu erlangen.
                    </p>
                    <p>
                        <div class="bold-text">Korruptionsverbot: </div>
                        Korruption ist für den Gouverneur, den US Marshall, den General der Kavallerie sowie dem Chefarzt untersagt.
                    </p>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default Rules;
